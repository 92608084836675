import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Container from "../components/layout/container"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" />
    <Container>
      <p>Please check your email to complete sign up</p>
    </Container>
  </Layout>
)

export default ThankYou
